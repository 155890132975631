import { infotekaApi as api } from "@/api/baseApi";
import { ListItemInterface } from "@/models/global/ListItem.interface";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { CountryInterface } from "@/models/global/CountryInterface";
import { ContainerTypesInterface } from "@/models/infoteka/containerTypes.interface";
import { LoadTypesInterface } from "@/models/infoteka/loadTypes.interface";
import { DangerousCargoItemInterface } from "@/models/order/DangerousCargoItem.interface";
import { SearchAndPagingInterface } from "@/models/searchAndPaging/SearchAndPaging.interface";

export async function getCargoCategories(
  req: SearchAndPagingInterface,
  signal: AbortSignal
): Promise<ListItemInterface[]> {
  const res: AxiosResponse<ListItemInterface[]> = await api.post(
    "/ProductCategories",
    req,
    { signal: signal } as AxiosRequestConfig
  );

  return res.data;
}

const existCargoCategoriesRequests: Record<
  string,
  Promise<AxiosResponse<ListItemInterface>>
> = {};
export function getCargoCategory(
  id: string,
  signal?: AbortSignal
): Promise<ListItemInterface> {
  if (existCargoCategoriesRequests[id])
    return existCargoCategoriesRequests[id].then(res => res.data);

  existCargoCategoriesRequests[id] = api.post("/ProductCategories/" + id, id, {
    signal: signal
  } as AxiosRequestConfig);

  return existCargoCategoriesRequests[id].then(res => res.data);
}

export async function getBodyTypes(
  req: SearchAndPagingInterface,
  signal: AbortSignal
): Promise<ListItemInterface[]> {
  const res: AxiosResponse<ListItemInterface[]> = await api.post(
    "/CarBodyTypes",
    req,
    { signal: signal } as AxiosRequestConfig
  );

  return res.data;
}

const existBodyTypesRequests: Record<
  string,
  Promise<AxiosResponse<ListItemInterface>>
> = {};
export function getBodyType(
  id: string,
  signal?: AbortSignal
): Promise<ListItemInterface> {
  if (existBodyTypesRequests[id])
    return existBodyTypesRequests[id].then(res => res.data);

  existBodyTypesRequests[id] = api.post("/CarBodyTypes/" + id, {}, {
    signal: signal
  } as AxiosRequestConfig);

  return existBodyTypesRequests[id].then(res => res.data);
}

export async function getLoadingTypes(
  req: SearchAndPagingInterface,
  signal: AbortSignal
): Promise<ListItemInterface[]> {
  const res: AxiosResponse<ListItemInterface[]> = await api.post(
    "/UnloadingTypes",
    req,
    { signal: signal } as AxiosRequestConfig
  );

  return res.data;
}

const existLoadingTypesRequests: Record<
  string,
  Promise<AxiosResponse<ListItemInterface>>
> = {};
export function getLoadingType(
  id: string,
  signal?: AbortSignal
): Promise<ListItemInterface> {
  if (existLoadingTypesRequests[id])
    return existLoadingTypesRequests[id].then(res => res.data);

  existLoadingTypesRequests[id] = api.post("/UnloadingTypes/" + id, {}, {
    signal: signal
  } as AxiosRequestConfig);

  return existLoadingTypesRequests[id].then(res => res.data);
}

export async function getVatRateTypes(): Promise<ListItemInterface[]> {
  const res: AxiosResponse<ListItemInterface[]> = await api.post(
    "/VatRatesTypes"
  );

  return res.data;
}

let existCountryCodesRequest: Promise<AxiosResponse<CountryInterface[]>>;
export async function getCountries(): Promise<CountryInterface[]> {
  if (existCountryCodesRequest)
    return await existCountryCodesRequest.then(res => res.data);

  existCountryCodesRequest = api.post("/CountryCodes");
  return await existCountryCodesRequest.then(res => res.data);
}

export async function getContainerTypes(
  req: SearchAndPagingInterface,
  signal: AbortSignal
): Promise<ContainerTypesInterface[]> {
  const res: AxiosResponse<ContainerTypesInterface[]> = await api.post(
    "/ContainerTypes",
    req,
    { signal: signal } as AxiosRequestConfig
  );

  return res.data;
}

const existContainerTypeRequests: Record<
  string,
  Promise<AxiosResponse<ContainerTypesInterface>>
> = {};
export function getContainerType(
  id: string,
  signal?: AbortSignal
): Promise<ContainerTypesInterface> {
  if (existContainerTypeRequests[id])
    return existContainerTypeRequests[id].then(res => res.data);

  existContainerTypeRequests[id] = api.post("/ContainerTypes/" + id, id, {
    signal: signal
  } as AxiosRequestConfig);

  return existContainerTypeRequests[id].then(res => res.data);
}

export async function getLoadTypes(): Promise<LoadTypesInterface[]> {
  const res: AxiosResponse<LoadTypesInterface[]> = await api.post("/LoadTypes");

  return res.data;
}

export async function getDangerousCargos(
  req: SearchAndPagingInterface,
  signal: AbortSignal
): Promise<DangerousCargoItemInterface[]> {
  const res: AxiosResponse<DangerousCargoItemInterface[]> = await api.post(
    "/DangerousLoads",
    req,
    { signal: signal } as AxiosRequestConfig
  );

  return res.data;
}
