import { OrderItemInterface } from "@/models/order/OrderItem.interface";
import pick from "lodash/pick";
import convertStringToNumber from "@/utils/convertStringToNumber";
import { compose, map, sum } from "lodash/fp";
import PersonService from "@/models/person/Person.service";
import { DimensionsInterface } from "@/models/order/Dimensions.interface";
import ContactService from "@/models/person/Contact.service";
import { PlaceRequestInterface } from "@/models/order/PlaceRequest.interface";
import { OrderRequestInterface } from "@/models/order/OrderRequest.interface";
import {
  createOrder,
  createOrders,
  getDefaultPlaceOwner,
  getOrderUploadOperationProgress,
  postDefaultPlaceOwner
} from "@/api/order";
import { InsuranceResponseInterface } from "@/models/order/InsuranceResponse";
import { ErrorResponseInterface } from "@/models/api/ErrorResponse.interface";
import { DataResponseInterface } from "@/models/api/DataResponse.interface";
import { OrderServiceInterface } from "@/models/order/Order.service.interface";
import { DimensionsFormInterface } from "@/models/order/DimensionsForm.interface";
import DimensionsFormModel from "@/models/order/DimensionsForm.model";
import { PlaceItemInterface } from "@/models/order/PlaceItem.interface";
import { NumberHelper } from "@/utils/Number.helper";
import { PeriodItemInterface } from "@/models/order/PeriodItem.interface";
import { integerDivision } from "@/utils/integerDivision";
import { toTimeString } from "@/utils/toTimeString";
import { OrderTypeEnum } from "@/models/order/OrderType.enum";
import { PersonInterface } from "@/models/person/Person.interface";
import { UploadOperationProgressInterface } from "./UploadOperationProgress.interface";
import { LoaderItemInterface } from "@/models/person/LoaderItem.interface";
import { LoaderInterface } from "@/models/person/Loader.interface";
import { LoadingOwnerItemInterface } from "@/models/person/LoadingOwnerItem.interface";
import { LoadingOwnerInterface } from "@/models/person/LoadingOwner.interface";
import { generateId } from "@/utils/generateId";
import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import returnSplitName from "@/mixins/returnSplitName";
import { getAddressKey } from "@/utils/formattedDateTime";
import {
  AddressObjectInterface,
  LoadingAddressObjectInterface
} from "@/models/order/AddressObject.interface";
import { OrderOptionsItemInterface } from "@/models/order/OrderOptionsItem.interface";
import { OrderOptionsInterface } from "@/models/order/OrderOptions.interface";
import { PlaceRequestOptionsInterface } from "@/models/order/PlaceRequestOptions.interface";
import { PlaceOptionsItemInterface } from "@/models/order/PlaceOptionsItem.interface";

export default class OrderService implements OrderServiceInterface {
  async createOrders(
    orders: OrderItemInterface[]
  ): Promise<ErrorResponseInterface | DataResponseInterface<string>> {
    const insuranceData = {
      withInsurance: false,
      orderGuid: "",
      insuranceInfo: null
    };
    const isFtl = true;

    const req = orders.map(order =>
      this.mapOrderRequest(order, insuranceData, isFtl)
    );

    return createOrders(req);
  }

  async createOrder(
    orderItem: OrderItemInterface,
    insuranceData: {
      withInsurance: boolean;
      orderGuid: string;
      insuranceInfo: InsuranceResponseInterface | null;
    } = { withInsurance: false, orderGuid: "", insuranceInfo: null },
    isFtl = true
  ): Promise<ErrorResponseInterface | DataResponseInterface<string>> {
    const req = this.mapOrderRequest(orderItem, insuranceData, isFtl);

    return createOrder(req);
  }

  static async getDefaultPlaceOwner(): Promise<PersonInterface | null> {
    const res = await getDefaultPlaceOwner();

    if (!res.isSuccess || res.entity == null) return null;

    return {
      id: res.entity.id,
      type: res.entity.type,
      name: res.entity.name ?? "",
      lastName: res.entity.lastName ?? "",
      firstName: res.entity.firstName ?? "",
      middleName: res.entity.middleName ?? "",
      inn: res.entity.inn ?? "",
      kpp: res.entity.kpp ?? "",
      ogrn: res.entity.ogrn ?? "",
      countryCode: res.entity.countryCode ?? "",
      address: res.entity.address ?? "",
      fullName: res.entity.fullName ?? ""
    } as PersonInterface;
  }

  static async postDefaultPlaceOwner(
    sender: OrderRequestInterface[]
  ): Promise<ErrorResponseInterface | DataResponseInterface> {
    return await postDefaultPlaceOwner(sender);
  }

  async getOrderUploadOperationProgress(
    operationId: string
  ): Promise<
    | ErrorResponseInterface
    | DataResponseInterface<UploadOperationProgressInterface>
  > {
    return getOrderUploadOperationProgress(operationId);
  }

  mapOrderRequest(
    orderItem: OrderItemInterface,
    insuranceData: {
      withInsurance: boolean;
      orderGuid: string;
      insuranceInfo: InsuranceResponseInterface | null;
    } = { withInsurance: false, orderGuid: "", insuranceInfo: null },
    isFtl = true
  ): OrderRequestInterface {
    const customerWithSplitName = PersonService.returnPersonWithSplitName<
      PersonItemInterface,
      PersonInterface
    >(orderItem.customer);
    const req = {
      ...pick(orderItem, [
        "comment",
        "tax",
        "type"
      ] as (keyof OrderItemInterface)[]),
      cost: {
        paymentMethod: "CARD",
        assessedValue: convertStringToNumber(orderItem.assessedValue),
        fullyPrepaid: false,
        manualDeliveryForCustomer: 0
      },
      customer: {
        id: orderItem.customer.id,
        type: orderItem.customer.type,
        name: orderItem.customer.name,
        inn: orderItem.customer.inn,
        ogrn: orderItem.customer.ogrn,
        kpp: orderItem.customer.kpp,
        fullName: orderItem.customer.fullName,
        firstName: customerWithSplitName.firstName,
        middleName: customerWithSplitName.middleName,
        lastName: customerWithSplitName.lastName,
        address: orderItem.customer.address,
        countryCode: orderItem.customer.countryCode
      },
      deliveryServiceExternalId: "",
      userId: "",
      withInsurance: insuranceData.withInsurance,
      externalId: orderItem.externalId,
      executorRoleType: orderItem.executorRoleType.toString(),
      isForwarded: orderItem.orderType != OrderTypeEnum.ToYourself,
      isVisibleToOpenHub: orderItem.isVisibleToOpenHub,
      forwardToOrganizationIds: [],
      forwardToOrganizationGroups: orderItem.forwardToOrganizationGroups.filter(
        (x: string) => Boolean(x) && x != "only-partners"
      ),
      forwardedPrice: null,
      auctionTime: "",
      auctionBidStep: 0,
      cancellationFreeOfChargeFeeForDelay: "",
      cancellationFreeOfChargeHours: "",
      daysAfterDocumentsReceiveForPayment: "",
      idleHoursForOrder: "",
      pricePerHourForExcessTime: "",
      otherConditionsText: "",
      executorLineClientId: orderItem.executorOrganization?.id ?? null,
      options: this.convertOptions(orderItem.options),
      insuranceInfo: insuranceData.insuranceInfo,
      id: !insuranceData.orderGuid ? generateId() : insuranceData.orderGuid,
      price: 0,
      templateId: orderItem.template?.id,
      places: orderItem.places
        .map((place, placeIndex) => {
          const fullVolume = convertStringToNumber(
            orderItem.options.fillFullVolume
              ? orderItem.dimensions.volume
              : place.dimensions.volume
          );
          const fullWeight = convertStringToNumber(
            orderItem.options.fillFullVolume
              ? orderItem.dimensions.weight
              : place.dimensions.weight
          );
          const fullLength = convertStringToNumber(
            orderItem.options.fillFullVolume
              ? orderItem.dimensions.length
              : place.dimensions.length
          );
          const fullWidth = convertStringToNumber(
            orderItem.options.fillFullVolume
              ? orderItem.dimensions.width
              : place.dimensions.width
          );
          const fullHeight = convertStringToNumber(
            orderItem.options.fillFullVolume
              ? orderItem.dimensions.height
              : place.dimensions.height
          );
          const fullPlaceCount = convertStringToNumber(orderItem.placesCount);
          const partsCount = orderItem.places.length;

          const onePartVolume = NumberHelper.floor(fullVolume / partsCount, -2);
          const onePartWeight = NumberHelper.floor(fullWeight / partsCount, -2);
          const onePartHeight = NumberHelper.floor(fullHeight / partsCount, -2);
          const onePartWidth = NumberHelper.floor(fullWidth / partsCount, -2);
          const onePartLength = NumberHelper.floor(fullLength / partsCount, -2);
          const onePartPlacesCount = NumberHelper.floor(
            fullPlaceCount / partsCount,
            0
          );

          const calculateLastDimension = (
            fullDimension: number,
            onePartDimension: number,
            partsCount: number
          ) => {
            const calculatedValue =
              (fullDimension * 100 -
                onePartDimension * 100 * (partsCount - 1)) /
              100;
            return Math.round(calculatedValue * 100) / 100;
          };

          const lastPartVolume = calculateLastDimension(
            fullVolume,
            onePartVolume,
            partsCount
          );
          const lastPartWeight = calculateLastDimension(
            fullWeight,
            onePartWeight,
            partsCount
          );
          const lastPartWidth = calculateLastDimension(
            fullWidth,
            onePartWidth,
            partsCount
          );
          const lastPartLength = calculateLastDimension(
            fullLength,
            onePartLength,
            partsCount
          );
          const lastPartHeight = calculateLastDimension(
            fullHeight,
            onePartHeight,
            partsCount
          );
          const lastPartPlacesCount = calculateLastDimension(
            fullPlaceCount,
            onePartPlacesCount,
            partsCount
          );

          const isLastPlace = placeIndex === orderItem.places.length - 1;

          function returnPlaceDimensions(
            isFullVolume: boolean,
            isLastPlaceRequest: boolean
          ): DimensionsInterface {
            if (!isFullVolume) {
              return {
                volume: convertStringToNumber(place.dimensions.volume),
                weight: convertStringToNumber(place.dimensions.weight),
                length: convertStringToNumber(place.dimensions.length),
                width: convertStringToNumber(place.dimensions.width),
                height: convertStringToNumber(place.dimensions.height)
              };
            }

            if (isLastPlaceRequest) {
              return {
                volume: lastPartVolume,
                weight: lastPartWeight,
                length: lastPartLength,
                width: lastPartWidth,
                height: lastPartHeight
              };
            }

            return {
              volume: onePartVolume,
              weight: onePartWeight,
              length: onePartLength,
              width: onePartWidth,
              height: onePartHeight
            };
          }

          const loadingAddressObj = place.loadingAddress;
          const unloadingAddressObj = place.unloadingAddress;

          const senderWithSplitName = PersonService.returnPersonWithSplitName(
            loadingAddressObj.person
          );
          const buildLoader = (
            loaderItem: LoaderItemInterface
          ): LoaderInterface => {
            const person = PersonService.returnPersonWithSplitName<
              PersonItemInterface,
              PersonInterface
            >(loaderItem);
            const employeeFullName = returnSplitName(
              loaderItem.employee.fullName
            );

            return {
              name: person.name,
              fullName: person.fullName,
              lastName: person.lastName,
              firstName: person.firstName,
              middleName: person.middleName,
              address: person.address,
              countryCode: person.countryCode,
              id: person.id,
              inn: person.inn,
              kpp: person.kpp,
              ogrn: person.ogrn,
              type: person.type,
              isSender: loaderItem.isSender,
              employee: {
                lastName: employeeFullName.lastName,
                firstName: employeeFullName.firstName,
                middleName: employeeFullName.middleName,
                position: loaderItem.employee.position,
                authorityToLoad: loaderItem.employee.authorityToLoad,
                contractData: loaderItem.employee.contractData
              },
              reasonToAct: loaderItem.reasonToAct
            };
          };
          const loadingOwnerWithSplitName: Omit<
            LoadingOwnerItemInterface & LoadingOwnerInterface,
            "contacts"
          > = PersonService.returnPersonWithSplitName<
            LoadingOwnerItemInterface,
            LoadingOwnerInterface
          >(loadingAddressObj.loadingOwner);

          const recipientWithSplitName = PersonService.returnPersonWithSplitName(
            unloadingAddressObj.person
          );

          const isLastUnloadingAddress =
            placeIndex === orderItem.places.length - 1;

          const loadingAddress = loadingAddressObj.address.returnAddress();
          loadingAddress.addressIndex =
            orderItem.route[
              getAddressKey(
                loadingAddressObj.address.value,
                loadingAddressObj.datePeriod
              )
            ];
          const unloadingAddress = unloadingAddressObj.address.returnAddress();
          unloadingAddress.addressIndex =
            orderItem.route[
              getAddressKey(
                unloadingAddressObj.address.value,
                unloadingAddressObj.datePeriod
              )
            ];

          return {
            count: 1,
            externalId: generateId(),
            name: `[${orderItem.type}] ${place.name}`,
            barcode: place.barcode,
            tax: isFtl ? orderItem.tax : place.tax,
            options: this.convertPlaceOptions(orderItem.options, place.options),
            comment: isFtl ? orderItem.comment : place.comment,
            items: [],
            isFragile: false,
            loadingAddress,
            unloadingAddress,
            dimensions: returnPlaceDimensions(
              orderItem.type.toLowerCase() !== "ltl" &&
                (orderItem.options?.fillFullVolume ?? false),
              isLastPlace
            ),
            assessedValue: convertStringToNumber(place.assessedValue),
            deliveryPrice: convertStringToNumber(place.deliveryPrice),
            shipment: {
              dateFrom: loadingAddressObj.datePeriod.dateFrom,
              dateTo: loadingAddressObj.datePeriod.isInterval
                ? loadingAddressObj.datePeriod.dateTo
                : loadingAddressObj.datePeriod.dateFrom,
              hourFrom: loadingAddressObj.datePeriod.hourFrom,
              hourTo: loadingAddressObj.datePeriod.isInterval
                ? loadingAddressObj.datePeriod.hourTo
                : loadingAddressObj.datePeriod.hourFrom
            },
            delivery: {
              dateFrom: unloadingAddressObj.datePeriod.dateFrom,
              dateTo: unloadingAddressObj.datePeriod.isInterval
                ? unloadingAddressObj.datePeriod.dateTo
                : unloadingAddressObj.datePeriod.dateFrom,
              hourFrom: unloadingAddressObj.datePeriod.hourFrom,
              hourTo: unloadingAddressObj.datePeriod.isInterval
                ? unloadingAddressObj.datePeriod.hourTo
                : unloadingAddressObj.datePeriod.hourFrom
            },
            sender: {
              ...senderWithSplitName,
              id: 0
            },
            recipient: { ...recipientWithSplitName, email: "" },
            senderContacts: loadingAddressObj.contacts.map(
              ContactService.returnContactWithSplitName
            ),
            recipientContacts: unloadingAddressObj.contacts.map(
              ContactService.returnContactWithSplitName
            ),
            loader: buildLoader(loadingAddressObj.loader),
            loaderContacts: loadingAddressObj.loader.contacts.map(
              ContactService.returnContactWithSplitName
            ),
            loadingOwner: loadingOwnerWithSplitName,
            loadingOwnerContacts: loadingAddressObj.loadingOwner.contacts.map(
              ContactService.returnContactWithSplitName
            ),
            cargoOptions: {
              cargoId: generateId(),
              ...returnPlaceDimensions(
                orderItem.type.toLowerCase() !== "ltl" &&
                  (orderItem.options?.fillFullVolume ?? false),
                isLastPlace
              ),
              assessedValue: convertStringToNumber(place.assessedValue),
              placesCount: orderItem.options.fillFullVolume
                ? isLastPlace
                  ? lastPartPlacesCount
                  : onePartPlacesCount
                : convertStringToNumber(place.placesCount)
            }
          } as PlaceRequestInterface;
        })
        .flat()
    } as OrderRequestInterface;

    req.otherConditionsText = orderItem.conditions.otherConditions;

    if (req.isForwarded) {
      const conditions = orderItem.conditions;
      const forwardedPrice = orderItem.forwardedPrice
        ? convertStringToNumber(orderItem.forwardedPrice)
        : null;
      const orderType = orderItem.orderType;
      const forwardToOrganizations = orderItem.forwardToOrganizations
        ? orderItem.forwardToOrganizations.filter(
            o => o?.isInBlackList === false
          )
        : [];
      const isAuction =
        (orderType === OrderTypeEnum.ToHub &&
          forwardToOrganizations &&
          forwardToOrganizations.length !== 1) ||
        orderItem.isAuction;

      req.auctionBidStep = convertStringToNumber(orderItem.auctionBidStep);
      req.auctionExtendActivationTime = orderItem.auctionExtendActivationTime;
      req.auctionExtendTime = orderItem.auctionExtendTime;
      req.auctionTime = orderItem.auctionTime;
      req.doHideMinPriceBid = orderItem.isMinimumBidHidden;
      req.extendTimeLimit = orderItem.extendTimeLimit;
      req.forwardedPrice = forwardedPrice;
      req.forwardToOrganizationIds = forwardToOrganizations.map(o => o.id);
      req.daysAfterDocumentsReceiveForPayment = convertStringToNumber(
        conditions.defermentOfPaymentDays
      ).toString();
      req.documentExchangeDaysAfterFreightUnloading = convertStringToNumber(
        conditions.documentExchangeDaysAfterFreightUnloading
      ).toString();
      req.documentsFormat = conditions.documentsFormat;
      req.isAuction = isAuction;
      req.isAuctionAutoComplete =
        forwardToOrganizations.length >= 2 && orderItem.isAuctionAutoComplete;
      req.isAuctionAutoExtend = orderItem.isAuctionAutoExtend;
      req.isPriceOnlyDescend =
        orderType != OrderTypeEnum.ToYourself &&
        orderItem.isPriceOnlyDescend &&
        forwardedPrice != 0 &&
        orderItem.forwardedPrice != "";

      req.isAuctionAutoExtend = orderItem.isAuctionAutoExtend;
      req.extendTimeLimit = orderItem.extendTimeLimit;
      req.auctionExtendActivationTime = orderItem.auctionExtendActivationTime;
      req.auctionExtendTime = orderItem.auctionExtendTime;
      req.documentExchangeDaysAfterFreightUnloading = convertStringToNumber(
        orderItem.conditions.documentExchangeDaysAfterFreightUnloading
      ).toString();
      req.daysAfterDocumentsReceiveForPayment = convertStringToNumber(
        orderItem.conditions.defermentOfPaymentDays
      ).toString();
      req.documentsFormat = orderItem.conditions.documentsFormat;
    }

    req.places.forEach(place => {
      place.externalId = generateId();
    });

    req.hasEdmData = orderItem.hasEdm;
    return req;
  }

  private convertOptions(
    options: OrderOptionsItemInterface
  ): OrderOptionsInterface {
    return {
      cargoCategories: options.cargoCategories.map(x => x.id),
      cargoSubCategory: options.cargoSubCategory,
      bodyTypes: options.bodyTypes.map(x => x.id),
      bodyTypeComment: options.bodyTypeComment,
      loadingTypes: options.loadingTypes.map(x => x.id),
      temperatureRegimes: options.temperatureRegimes,
      wouldBeLoaded: options.wouldBeLoaded,
      allInSingleTransport: options.allInSingleTransport,
      fillFullVolume: options.fillFullVolume,
      fillFullAssessedValue: options.fillFullAssessedValue
    };
  }

  private convertPlaceOptions(
    options: OrderOptionsItemInterface,
    placeOptions: PlaceOptionsItemInterface
  ): PlaceRequestOptionsInterface {
    return {
      cargoCategories: options.cargoCategories.map(x => x.id),
      cargoSubCategory: options.cargoSubCategory,
      bodyTypes: options.bodyTypes.map(x => x.id),
      bodyTypeComment: options.bodyTypeComment,
      loadingTypes: options.loadingTypes.map(x => x.id),
      temperatureRegimes: options.temperatureRegimes,
      wouldBeLoaded: options.wouldBeLoaded,
      allInSingleTransport: options.allInSingleTransport,
      fillFullVolume: options.fillFullVolume,
      fillFullAssessedValue: options.fillFullAssessedValue,
      containerType: placeOptions.containerType?.id ?? "",
      cargoType: placeOptions.cargoType?.id ?? "",
      cargoCondition: placeOptions.cargoCondition,
      cargoShippingTitle:
        (placeOptions.cargoType?.code === "Opasnijgruz"
          ? placeOptions.dangerousCargoData?.id
          : placeOptions.cargoShippingTitle) ?? "",
      dangerousClassCode: placeOptions.dangerousClassCode,
      packingMethod: placeOptions.packingMethod
    };
  }

  static setDatePeriodHour(
    datePeriod: PeriodItemInterface,
    hour: string,
    isHourTo = false
  ): void {
    const key: keyof Pick<PeriodItemInterface, "hourFrom" | "hourTo"> = isHourTo
      ? "hourTo"
      : "hourFrom";

    if (hour.replace("_", "").length < 5) {
      datePeriod[key] = hour;
      return;
    }

    // eslint-disable-next-line prefer-const
    let [hours, minutes] = hour.split(":", 2).map(val => +val);

    if (hours > 24 || minutes > 60) {
      datePeriod[key] = hour;
      return;
    }

    minutes = 15 * (integerDivision(minutes, 15) + (minutes % 15 > 7 ? 1 : 0));

    datePeriod[key] = toTimeString(hours, minutes, 0, "HH:mm");
  }

  private divideDimensionToPlaces(
    order: OrderItemInterface,
    dimensionName: keyof DimensionsFormInterface,
    value: string
  ) {
    const numericValue = convertStringToNumber(value);
    const dividedNumVal = numericValue / order.places.length;
    const dividedNumValRounded = Math.round(dividedNumVal * 100) / 100;
    const dividedVal = NumberHelper.numberToString(dividedNumValRounded);

    order.places.forEach(place => {
      place.dimensions[dimensionName] = dividedVal === "0" ? "" : dividedVal;
    });
  }

  setDimensionToOrder(
    order: OrderItemInterface,
    dimensionName: keyof DimensionsFormInterface,
    value: string,
    isCalcVolume = true
  ): void {
    order.dimensions[dimensionName] = value;

    this.divideDimensionToPlaces(order, dimensionName, value);

    if (!["width", "length", "height"].includes(dimensionName)) return;

    if (isCalcVolume) {
      const orderVolumeValue = DimensionsFormModel.calcVolume(order.dimensions);
      this.setDimensionToOrder(order, "volume", orderVolumeValue);
    }
  }

  setDimensionToOrderPlace(
    order: OrderItemInterface,
    placeIdx: number,
    dimensionName: keyof DimensionsFormInterface,
    value: string
  ): void {
    const place = order.places[placeIdx];
    place.dimensions[dimensionName] = value;

    this.calculateOrderDimension(order, dimensionName);

    if (["weight", "volume"].includes(dimensionName)) return;

    const placeVolumeValue = DimensionsFormModel.calcVolume(place.dimensions);
    this.setDimensionToOrderPlace(order, placeIdx, "volume", placeVolumeValue);
  }

  calculateOrderDimensions(order: OrderItemInterface) {
    Object.entries(order.dimensions).forEach(([key]: [string, unknown]) =>
      this.calculateOrderDimension(order, key as keyof DimensionsFormInterface)
    );
  }

  calculateOrderDimension(
    order: OrderItemInterface,
    dimensionName: keyof DimensionsFormInterface
  ) {
    order.dimensions[dimensionName] = compose(
      value => (value === "0" ? "" : value),
      NumberHelper.numberToString,
      sum,
      map((place: PlaceItemInterface) =>
        convertStringToNumber(place.dimensions[dimensionName])
      )
    )(order.places);
  }

  private divideAssessedValueToPlaces(
    order: OrderItemInterface,
    value: string
  ) {
    const numericValue = convertStringToNumber(value);
    const dividedValue = NumberHelper.numberToString(
      numericValue / order.places.length
    );

    order.places.forEach(place => {
      place.assessedValue = dividedValue;
    });
  }

  private dividePlacesCountToPlaces(order: OrderItemInterface, value: string) {
    const numericValue = convertStringToNumber(value);
    const dividedValue = Math.floor(numericValue / order.places.length);

    order.places.forEach((place, idx) => {
      if (idx === order.places.length - 1) {
        place.placesCount = NumberHelper.numberToString(
          numericValue - dividedValue * (order.places.length - 1)
        );
        return;
      }
      place.placesCount = NumberHelper.numberToString(dividedValue);
    });
  }

  private dividePriceToPlaces(order: OrderItemInterface, value: string) {
    const numericValue = convertStringToNumber(value);
    const dividedValue = NumberHelper.numberToString(
      numericValue / order.places.length
    );

    order.places.forEach(place => {
      place.deliveryPrice = dividedValue;
    });
  }

  setAssessedValueToOrder(order: OrderItemInterface, value: string) {
    order.assessedValue = value;
    this.divideAssessedValueToPlaces(order, value);
  }

  setAssessedValueToOrderPlace(
    order: OrderItemInterface,
    placeIdx: number,
    value: string
  ) {
    const place = order.places[placeIdx];
    place.assessedValue = value;

    order.assessedValue = compose(
      NumberHelper.numberToString,
      sum,
      map((place: PlaceItemInterface) =>
        convertStringToNumber(place.assessedValue)
      )
    )(order.places);
  }

  setPlacesCountToOrder(order: OrderItemInterface, value: string) {
    order.placesCount = value;
    this.dividePlacesCountToPlaces(order, value);
  }

  setPlacesCountToOrderPlace(
    order: OrderItemInterface,
    placeIdx: number,
    value: string
  ) {
    const place = order.places[placeIdx];
    place.placesCount = value;

    this.calculateOrderPlacesCount(order);
  }

  private calculateOrderPlacesCount(order: OrderItemInterface) {
    order.placesCount = compose(
      NumberHelper.numberToString,
      sum,
      map((place: PlaceItemInterface) =>
        convertStringToNumber(place.placesCount)
      )
    )(order.places);
  }

  setPriceToOrder(order: OrderItemInterface, value: string) {
    order.price = value;
    this.dividePriceToPlaces(order, value);
  }

  addPlaceToOrder(order: OrderItemInterface): void {
    order.addPlace();
    this.handleOrderActions(order);
  }

  addPlaceToOrderWithLoadingAddress(
    order: OrderItemInterface,
    loadingAddress: LoadingAddressObjectInterface,
    indexToAdd: number
  ): void {
    order.addPlaceWithLoadingAddress(loadingAddress, indexToAdd);
    this.handleOrderActions(order);
  }

  addPlaceToOrderWithUnloadingAddress(
    order: OrderItemInterface,
    unloadingAddress: AddressObjectInterface,
    indexToAdd: number
  ): void {
    order.addPlaceWithUnloadingAddress(unloadingAddress, indexToAdd);
    this.handleOrderActions(order);
  }

  removePlace(order: OrderItemInterface, placeIdx: number): void {
    order.removePlace(placeIdx);
    this.handleOrderActions(order);
  }

  private handleOrderActions(order: OrderItemInterface): void {
    // Выполняем действия на основе типа заказа
    if (order.type === "Ftl") {
      this.handleFullTruckLoad(order);
    } else {
      this.handleOptions(order);
    }
  }

  private handleOptions(order: OrderItemInterface): void {
    if (order.options.fillFullAssessedValue) {
      this.divideAssessedValueToPlaces(order, order.assessedValue);
    }

    if (order.options.fillFullVolume) {
      this.handleFullVolume(order);
    } else {
      this.calculateOrderDimensions(order);
      this.calculateOrderPlacesCount(order);
    }
  }

  private handleFullTruckLoad(order: OrderItemInterface): void {
    this.dividePriceToPlaces(order, order.price);

    if (order.options.fillFullVolume) {
      this.handleFullVolume(order);
    } else {
      this.calculateOrderDimensions(order);
      this.calculateOrderPlacesCount(order);
    }

    if (order.options.fillFullAssessedValue) {
      this.divideAssessedValueToPlaces(order, order.assessedValue);
    }
  }

  private handleFullVolume(order: OrderItemInterface): void {
    Object.keys(order.dimensions).forEach(dimensionName => {
      this.divideDimensionToPlaces(
        order,
        dimensionName as keyof DimensionsFormInterface,
        order.dimensions[dimensionName as keyof DimensionsFormInterface]
      );
    });

    order.placesCount = NumberHelper.numberToString(
      Math.max(
        order.places.length,
        order.placesCount ? convertStringToNumber(order.placesCount) : 1
      )
    );

    this.dividePlacesCountToPlaces(order, order.placesCount);
  }

  setHasEdmValue(order: OrderItemInterface, hasEdm: boolean) {
    if (order.type !== "Ftl") return;

    order.hasEdm = hasEdm;
  }
}
