import { PlaceOptionsItemInterface } from "@/models/order/PlaceOptionsItem.interface";
import { DangerousCargoItemInterface } from "@/models/order/DangerousCargoItem.interface";
import { LoadTypesInterface } from "@/models/infoteka/loadTypes.interface";
import { ContainerTypesInterface } from "@/models/infoteka/containerTypes.interface";

export class PlaceOptionsModel implements PlaceOptionsItemInterface {
  cargoCondition: string;
  cargoShippingTitle: string;
  cargoType: LoadTypesInterface | null;
  containerType: ContainerTypesInterface | null;
  packingMethod: string;
  dangerousClassCode: string;
  dangerousCargoData: DangerousCargoItemInterface | null;

  constructor() {
    this.cargoType = null;
    this.cargoCondition = "";
    this.cargoShippingTitle = "";
    this.dangerousClassCode = "";
    this.containerType = null;
    this.dangerousCargoData = null;
    this.packingMethod = "";
  }
}
