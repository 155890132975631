















import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
  name: "BaseInput",
  props: {
    label: {
      type: String,
      required: true
    },
    isRequired: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    id: {
      type: String as PropType<string>,
      default: ""
    }
  },
  setup(props) {
    const computedLabel = computed(() => {
      return props.isRequired ? props.label + "*" : props.label;
    });

    return {
      computedLabel
    };
  }
});
