import {
  getExecutorOrganizations,
  getOrganizationsToForwardOrder,
  importFile
} from "@/api/order";
import {
  PersonCompanyInterface,
  PersonEntrepreneurInterface,
  PersonIndividualInterface,
  PersonType
} from "@/models/person/Person.interface";
import { PersonItemInterface } from "@/models/person/PersonItem.interface";
import PlaceItemModel from "@/models/order/PlaceItem.model";
import { AddressObjectInterface } from "@/models/order/AddressObject.interface";
import ContactItemModel from "@/models/order/ContactItem.model";
import { ImportOrderListItemInterface } from "@/models/import/ImportOrderListItem.interface";
import ImportOrderListItemModel from "@/models/import/ImportOrderListItem.model";
import { NumberHelper } from "@/utils/Number.helper";
import { OrderServiceInterface } from "@/models/order/Order.service.interface";
import OrderService from "@/models/order/Order.service";
import convertStringToNumber from "@/utils/convertStringToNumber";
import { YandexMapService } from "@/models/yandex-map/YandexMap.service";
import { OrderTypeEnum } from "@/models/order/OrderType.enum";
import {
  ExecutorOrganizationInterface,
  ExecutorRoleTypeEnum,
  OrganizationViewInterface
} from "@/models/global/OrganizationView.interface";
import { DimensionsFormInterface } from "@/models/order/DimensionsForm.interface";
import { OrderRequestInterface } from "../order/OrderRequest.interface";
import { OrderOptionsItemInterface } from "@/models/order/OrderOptionsItem.interface";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";
import { PlaceRequestInterface } from "@/models/order/PlaceRequest.interface";
import { useUserStore } from "@/store/useUserStore";
import {
  UserActionsInterface,
  UserGettersInterface,
  UserStateInterface
} from "@/models/store/UserState.interface";
import { Store } from "pinia";
import { OrderOptionsInterface } from "@/models/order/OrderOptions.interface";
import {
  getBodyType,
  getCargoCategory,
  getContainerType,
  getLoadingType
} from "@/api/infoteka";
import { PlaceItemInterface } from "@/models/order/PlaceItem.interface";

export default class ImportService {
  private _orderService: OrderServiceInterface;
  private _userStore: Store<
    "user",
    UserStateInterface,
    UserGettersInterface,
    UserActionsInterface
  >;

  public addressIndexList: number[] = [];

  constructor() {
    this._orderService = new OrderService();
    this._userStore = useUserStore();
  }

  static async fillPerson(
    person: PersonItemInterface,
    data:
      | PersonCompanyInterface
      | PersonEntrepreneurInterface
      | PersonIndividualInterface
  ) {
    person.type = data.type;
    person.countryCode = data.countryCode ?? "RUS";
    person.address = data.address || "";

    if (data.type === PersonType.COMPANY) {
      person.inn = data.inn;
      person.name = data.name;
      return;
    } else {
      person.lastName = data.lastName;
      person.firstName = data.firstName;
      person.middleName = data.middleName;
      person.fullName = person.getFullName();
    }

    if (data.type !== PersonType.ENTREPRENEUR) return;

    person.inn = data.inn;
  }

  static async fillOrderItemOptions(
    orderItemOptions: OrderOptionsItemInterface,
    orderOptions: OrderOptionsInterface
  ) {
    orderItemOptions.fillFullAssessedValue = orderOptions.fillFullAssessedValue;
    orderItemOptions.loadingTypes = await Promise.all(
      orderOptions.loadingTypes.map(id => {
        return getLoadingType(id);
      })
    );
    orderItemOptions.bodyTypes = await Promise.all(
      orderOptions.bodyTypes.map(id => {
        return getBodyType(id);
      })
    );
    orderItemOptions.bodyTypeComment = orderOptions.bodyTypeComment;
    orderItemOptions.cargoCategories = await Promise.all(
      orderOptions.cargoCategories.map(id => {
        return getCargoCategory(id);
      })
    );
    orderItemOptions.cargoSubCategory = orderOptions.cargoSubCategory;
    orderItemOptions.temperatureRegimes = orderOptions.temperatureRegimes;
    orderItemOptions.fillFullVolume = orderOptions.fillFullVolume;
    orderItemOptions.wouldBeLoaded = orderOptions.wouldBeLoaded || false;
    orderItemOptions.allInSingleTransport =
      orderOptions.allInSingleTransport || false;
  }

  static async fillOrderItemFields(
    orderItem: OrderItemInterface,
    order: OrderRequestInterface,
    forwardToOrganizationsByExecutorLineClient: Record<
      string,
      OrganizationViewInterface[]
    >
  ) {
    orderItem.orderType = order.isForwarded
      ? OrderTypeEnum.ToHub
      : OrderTypeEnum.ToYourself;
    orderItem.forwardedPrice = order.forwardedPrice
      ? order.forwardedPrice.toString()
      : "";
    orderItem.conditions.documentExchangeDaysAfterFreightUnloading =
      order.documentExchangeDaysAfterFreightUnloading;
    orderItem.conditions.defermentOfPaymentDays =
      order.daysAfterDocumentsReceiveForPayment;
    orderItem.conditions.documentsFormat = order.documentsFormat;
    orderItem.isMinimumBidHidden = order.doHideMinPriceBid;
    if (
      order.forwardToOrganizationIds.length > 0 &&
      orderItem.executorOrganization != null
    ) {
      let forwardToOrganizations =
        forwardToOrganizationsByExecutorLineClient[
          orderItem.executorOrganization.id
        ];
      if (forwardToOrganizations == null) {
        const res = await getOrganizationsToForwardOrder(
          orderItem.executorRoleType,
          orderItem.executorOrganization.id,
          "",
          true
        );

        forwardToOrganizations = res.isSuccess ? res.entity : [];
      }

      if (forwardToOrganizations.length > 0) {
        orderItem.forwardToOrganizations = order.forwardToOrganizationIds
          .map(x => {
            return forwardToOrganizations.find(org => org.id === x);
          })
          .filter(x => x != null) as OrganizationViewInterface[];
      }
    }

    if (orderItem.orderType === OrderTypeEnum.ToHub) {
      orderItem.isAuction = order.isAuction;
      orderItem.isVisibleToOpenHub = order.isVisibleToOpenHub;
      orderItem.auctionTime = order.auctionTime;
      orderItem.auctionBidStep =
        order.auctionBidStep != null && order.auctionBidStep > 0
          ? order.auctionBidStep.toString()
          : "500";
      orderItem.isAuctionAutoComplete = order.doHideMinPriceBid
        ? false
        : order.isAuctionAutoComplete;
      orderItem.isPriceOnlyDescend = order.doHideMinPriceBid
        ? false
        : order.isPriceOnlyDescend;
      orderItem.isAuctionAutoExtend = order.doHideMinPriceBid
        ? false
        : order.isAuctionAutoExtend;
      orderItem.extendTimeLimit = order.extendTimeLimit ?? "";
      orderItem.auctionExtendActivationTime =
        order.auctionExtendActivationTime ?? "";
      orderItem.auctionExtendTime = order.auctionExtendTime ?? "";
    }
  }

  private static async fillAddress(
    addressObj: AddressObjectInterface,
    fullAddress: string
  ) {
    const searchedAddress = await YandexMapService.geocode(fullAddress);
    if (searchedAddress.length !== 0) {
      const firstSearchedAddress = searchedAddress[0];
      addressObj.address.value = firstSearchedAddress.value;
      addressObj.address.data = firstSearchedAddress.data;
      return;
    }
    addressObj.address.value = fullAddress;
  }
  static async fillOrderItem(
    orderItem: OrderItemInterface,
    order: OrderRequestInterface
  ) {
    orderItem.externalId = order.externalId;
    orderItem.type = order.type || "FTL";
    orderItem.price = NumberHelper.numberToString(order.price);
    orderItem.comment = order.comment;
    orderItem.tax = order.tax;
    orderItem.assessedValue = NumberHelper.numberToString(
      order.cost?.assessedValue ?? 0
    );
  }

  async fillOrderItemPlaces(
    orderPlaces: PlaceRequestInterface[],
    orderType: "FTL" | "LTL"
  ): Promise<PlaceItemInterface[]> {
    return Promise.all(
      orderPlaces.map(async place => {
        const placeItem = new PlaceItemModel();

        placeItem.barcode = place.barcode;
        placeItem.name = place.name.replace(`[${orderType}] `, "");
        placeItem.dimensions = {
          weight: NumberHelper.numberToString(place.dimensions.weight),
          volume: NumberHelper.numberToString(place.dimensions.volume),
          height: NumberHelper.numberToString(place.dimensions.height),
          length: NumberHelper.numberToString(place.dimensions.length),
          width: NumberHelper.numberToString(place.dimensions.width)
        };
        placeItem.placesCount =
          place?.cargoOptions &&
          place.cargoOptions.placesCount != null &&
          place.cargoOptions.placesCount > 0
            ? NumberHelper.numberToString(place.cargoOptions.placesCount)
            : "1";
        placeItem.assessedValue = NumberHelper.numberToString(
          place.assessedValue
        );
        if (place.options.containerType) {
          placeItem.options.containerType = await getContainerType(
            place.options.containerType
          );
        }

        const loadingAddress = placeItem.loadingAddress;
        const unloadingAddress = placeItem.unloadingAddress;

        if (place.loadingAddress.fullAddress.length > 3) {
          await ImportService.fillAddress(
            loadingAddress,
            place.loadingAddress.fullAddress
          );
        }
        if (place.unloadingAddress.fullAddress.length > 3) {
          await ImportService.fillAddress(
            unloadingAddress,
            place.unloadingAddress.fullAddress
          );
        }

        await ImportService.fillPerson(loadingAddress.person, place.sender);
        await ImportService.fillPerson(
          unloadingAddress.person,
          place.recipient
        );

        loadingAddress.address.data.addressIndex =
          place.loadingAddress.addressIndex;
        unloadingAddress.address.data.addressIndex =
          place.unloadingAddress.addressIndex;
        this.addressIndexList.push(
          place.loadingAddress.addressIndex,
          place.unloadingAddress.addressIndex
        );

        loadingAddress.contacts = place.senderContacts.map(contact => {
          const contactItem = new ContactItemModel();

          contactItem.fullName = `${contact.lastName} ${contact.firstName} ${contact.middleName}`;
          contactItem.phone = contact.phone;
          contactItem.countryCode = contact.countryCode ?? "RUS";

          return contactItem;
        });
        unloadingAddress.contacts = place.recipientContacts.map(contact => {
          const contactItem = new ContactItemModel();

          contactItem.fullName = `${contact.lastName} ${contact.firstName} ${contact.middleName}`;
          contactItem.phone = contact.phone;
          contactItem.countryCode = contact.countryCode ?? "RUS";

          return contactItem;
        });

        if (place.shipment) {
          loadingAddress.datePeriod.dateFrom = place.shipment?.dateFrom ?? "";
          loadingAddress.datePeriod.dateTo = place.shipment?.dateTo ?? "";
          loadingAddress.datePeriod.hourFrom =
            place.shipment?.hourFrom?.slice(0, 5) ?? "";
          loadingAddress.datePeriod.hourTo =
            place.shipment.hourTo?.slice(0, 5) ?? "";
          loadingAddress.datePeriod.isInterval =
            (!!loadingAddress.datePeriod.dateTo ||
              !!loadingAddress.datePeriod.hourTo) &&
            (loadingAddress.datePeriod.dateFrom !=
              loadingAddress.datePeriod.dateTo ||
              loadingAddress.datePeriod.hourFrom !=
                loadingAddress.datePeriod.hourTo);
        }

        if (place.delivery) {
          unloadingAddress.datePeriod.dateFrom = place.delivery.dateFrom;
          unloadingAddress.datePeriod.dateTo = place.delivery.dateTo;
          unloadingAddress.datePeriod.hourFrom =
            place.delivery.hourFrom?.slice(0, 5) ?? "";
          unloadingAddress.datePeriod.hourTo =
            place.delivery.hourTo?.slice(0, 5) ?? "";
          unloadingAddress.datePeriod.isInterval =
            (!!unloadingAddress.datePeriod.dateTo ||
              !!unloadingAddress.datePeriod.hourTo) &&
            (unloadingAddress.datePeriod.dateFrom !=
              unloadingAddress.datePeriod.dateTo ||
              unloadingAddress.datePeriod.hourFrom !=
                unloadingAddress.datePeriod.hourTo);
        }

        return placeItem;
      })
    );
  }

  async importFile(
    file: FormData
  ): Promise<ImportOrderListItemInterface[] | null> {
    const res = await importFile(file);

    if (!res.isSuccess || res.entity.length === 0) {
      return null;
    }

    const executorOrganizations = (await getExecutorOrganizations()).entity;
    const forwardToOrganizationsByExecutorLineClient: Record<
      string,
      OrganizationViewInterface[]
    > = {};

    return await Promise.all(
      res.entity.map(order =>
        this.mapOrderRequest(
          order,
          executorOrganizations,
          forwardToOrganizationsByExecutorLineClient
        )
      )
    );
  }

  async mapOrderRequest(
    order: OrderRequestInterface,
    executorOrganizations: ExecutorOrganizationInterface[] | null,
    forwardToOrganizationsByExecutorLineClient: Record<
      string,
      OrganizationViewInterface[]
    >
  ): Promise<ImportOrderListItemInterface> {
    const orderItem = new ImportOrderListItemModel();
    await ImportService.fillOrderItem(orderItem, order);
    if (order.options !== null)
      await ImportService.fillOrderItemOptions(
        orderItem.options,
        order.options
      );

    await ImportService.fillPerson(orderItem.customer, order.customer);

    if (!order.executorLineClientId) {
      orderItem.executorOrganization =
        executorOrganizations && executorOrganizations.length > 0
          ? executorOrganizations[0]
          : null;
    } else {
      orderItem.executorOrganization =
        (executorOrganizations &&
          executorOrganizations.find(
            o => o.id === order.executorLineClientId
          )) ||
        null;
    }
    this._userStore.SET_OWNER_COUNTRY(
      orderItem.executorOrganization?.countryCode ?? ""
    );

    orderItem.places = await this.fillOrderItemPlaces(order.places, order.type);

    const hasNoValidIndex = this.addressIndexList.some(a => a === -1);
    const hasEmptyIndex = this.addressIndexList.some(a => a === 0);
    const hasValidIndex = this.addressIndexList.some(a => a > 0);

    if (hasNoValidIndex || (hasEmptyIndex && hasValidIndex)) {
      orderItem.isRouteOrderError = true;
    }

    if (orderItem.options.fillFullVolume) {
      Object.keys(orderItem.dimensions).forEach(
        (dimName: keyof DimensionsFormInterface) => {
          if (
            convertStringToNumber(
              orderItem.places[0].dimensions[
                dimName as keyof DimensionsFormInterface
              ]
            ) === 0
          )
            return;

          this._orderService.setDimensionToOrder(
            orderItem,
            dimName,
            orderItem.places[0].dimensions[dimName]
          );
        }
      );

      const placesCount = Math.max(
        orderItem.places.length,
        convertStringToNumber(orderItem.places[0].placesCount)
      ).toString();

      this._orderService.setPlacesCountToOrder(orderItem, placesCount);
    } else {
      orderItem.places.forEach((place, idx) => {
        Object.keys(place.dimensions).forEach(dimName => {
          if (
            convertStringToNumber(
              place.dimensions[dimName as keyof DimensionsFormInterface]
            ) === 0
          )
            return;

          this._orderService.setDimensionToOrderPlace(
            orderItem,
            idx,
            dimName as keyof DimensionsFormInterface,
            place.dimensions[dimName as keyof DimensionsFormInterface]
          );
        });

        this._orderService.setPlacesCountToOrderPlace(
          orderItem,
          idx,
          place.placesCount
        );
      });
    }

    if (order.options?.fillFullAssessedValue) {
      if (convertStringToNumber(orderItem.places[0].assessedValue) !== 0) {
        this._orderService.setAssessedValueToOrder(
          orderItem,
          orderItem.places[0].assessedValue
        );
      }
    } else {
      orderItem.places.forEach((place, idx) => {
        this._orderService.setAssessedValueToOrderPlace(
          orderItem,
          idx,
          place.assessedValue
        );
      });
    }

    orderItem.conditions.otherConditions = order.otherConditionsText;

    switch (order.executorRoleType) {
      case "0":
        orderItem.executorRoleType = ExecutorRoleTypeEnum.Any;
        break;
      case "1":
        orderItem.executorRoleType = ExecutorRoleTypeEnum.Forwarding;
        break;
      case "2":
        orderItem.executorRoleType = ExecutorRoleTypeEnum.Delivery;
        break;
      default:
        orderItem.executorRoleType = ExecutorRoleTypeEnum.Any;
    }

    if (order.isForwarded)
      await ImportService.fillOrderItemFields(
        orderItem,
        order,
        forwardToOrganizationsByExecutorLineClient
      );

    this._orderService.setPriceToOrder(orderItem, orderItem.price);

    return orderItem;
  }
}
