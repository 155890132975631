import { ConditionsInterface } from "@/models/order/Conditions.interface";
import { OrderTypeEnum } from "@/models/order/OrderType.enum";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";

export class ConditionsModel implements ConditionsInterface {
  otherConditions: string;
  documentExchangeDaysAfterFreightUnloading: string;
  documentsFormat: string;
  defermentOfPaymentDays: string;
  defermentOfPaymentDaysIsRequired: () => boolean;
  documentExchangeDaysAfterFreightUnloadingIsRequired: () => boolean;
  documentsFormatIsRequired: () => boolean;

  constructor(order: OrderItemInterface) {
    this.defermentOfPaymentDays = "";
    this.otherConditions = "";
    this.documentsFormat = "";
    this.documentExchangeDaysAfterFreightUnloading = "";

    this.defermentOfPaymentDaysIsRequired = () => {
      return order.orderType === OrderTypeEnum.ToHub;
    };
    this.documentExchangeDaysAfterFreightUnloadingIsRequired = () => {
      return order.orderType === OrderTypeEnum.ToHub;
    };
    this.documentsFormatIsRequired = () => {
      return order.orderType === OrderTypeEnum.ToHub;
    };
  }
}
