import { OrderOptionsItemInterface } from "@/models/order/OrderOptionsItem.interface";
import { ListItemInterface } from "@/models/global/ListItem.interface";

export default class OrderOptionsItemModel
  implements OrderOptionsItemInterface {
  bodyTypes: ListItemInterface[];
  bodyTypeComment: string;
  cargoCategories: ListItemInterface[];
  cargoSubCategory: string;
  loadingTypes: ListItemInterface[];
  temperatureRegimes: string[];
  fillFullVolume: boolean;
  fillFullAssessedValue: boolean;
  wouldBeLoaded: boolean;
  allInSingleTransport: boolean;

  constructor() {
    this.bodyTypes = [];
    this.bodyTypeComment = "";
    this.cargoCategories = [];
    this.cargoSubCategory = "";
    this.loadingTypes = [];
    this.temperatureRegimes = [];
    this.fillFullVolume = true;
    this.fillFullAssessedValue = true;
    this.wouldBeLoaded = false;
    this.allInSingleTransport = false;
  }
}
